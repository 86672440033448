<template>
    <div>
        <b-navbar class="customNavbar" toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand href="#" id="title">CLAIMLINQ</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse">
                <template v-slot:default="{ expanded }">
                    <i v-if="expanded" class="bx bx-x"></i>
                    <i v-else class="bx bx-menu"></i>
                </template>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-button variant="primary" id="loginBtn" style="margin-right: 10px" @click="goToLogin()">Login</b-button>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <b-container fluid>

            <b-row style="height: 350px"></b-row>
            <b-row style="height: 250px;background-color: white"></b-row>
            <b-row style="height: 70px;background-color: #1E1D1D" align-v="center" class="footer">
                <b-col><span class="footerText">ClaimLinq 2021</span></b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "HomePage",
        methods: {
            goToLogin() {
                this.$router.push('/login');
            },
            goToDashboard() {
               this.$router.push('/dashboard');

            },
        },
        mounted() {
            if (this.isLoggedIn) {
                this.goToDashboard();
            }else{
              this.goToLogin()
            }
        },
        computed: {
            ...mapGetters({
                isLoggedIn: 'auth/isLoggedIn',
            }),
        },

        watch: {
            isLoggedIn(val) {
                if (val) {
                    this.goToDashboard();
                }
            },
        }
    };

</script>

<style scoped>
    .navbar.navbar-dark.bg-dark {
        background-color: rgb(33, 35, 48) !important;
    }

    .navbar-toggler {
        border: none !important;
    }

    .bx-menu {
        font-size: 2em !important;
    }

    .bx-x {
        font-size: 2em !important;
    }

    @media (min-width: 992px) {
        .navbar.navbar-dark.bg-dark {
            background-color: rgb(33, 35, 48) !important;
            padding-right: 200px;
            padding-left: 200px
        }
    }

    #title {
        font-size: 26px;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 35px;
        font-family: "Nunito Sans";
    }

    #registerBtn {
        background-color: #29BBC1;
        font-weight: bold;
        font-family: "Nunito Sans";
        padding: 13px 35px;
        font-size: 16px;
        font-weight: bold;
    }

    #loginBtn {
        color: white;
        border: none;
        font-weight: bold;
        font-family: "Nunito Sans";
        padding: 13px 35px;
        font-size: 16px;
        font-weight: bold;
    }

    .footer .footerText {
        font-family: "Nunito Sans";
        color: white;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
    }

    .footer .col {
        padding-right: 200px;
        padding-left: 200px;
    }

    @media (max-width: 425px) {
        .footer .col {
            padding-left: 100px;
            padding-right: 0px;
        }
    }
</style>
