<template>
    <div>
        <div class="tabs-component" :class="viewClass">
            <div class="tab-header view-header-buttons" v-if="$slots['nav-item-center']">
                <slot name="nav-item-center"/>
            </div>

            <ul role="tablist" class="tab-header nav nav-tabs nav-tabs-2 float-sm-left">
                <li v-for="(tab, i) in computedTabs"
                    :key="i"
                    :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
                    class="tabs-component-tab nav-item"
                    role="presentation"
                    v-show="tab.isVisible"
                    @contextmenu.prevent="handleRightClick($event, i)"
                >
                    <a v-html="tab.header"
                       :aria-controls="tab.hash"
                       :aria-selected="tab.isActive"
                       @click.prevent="selectTab(tab.hash, $event)"
                       :class="{ 'active': tab.isActive, 'disabled': tab.isDisabled }"
                       :href="tab.hash"
                       class="tabs-component-tab-a  nav-link"
                       role="tab"></a>
                </li>
                <slot name="nav-item-tab-button"/>
            </ul>
            <slot name="right-context-item-menu"/>
            <div class="tab-header rs-btn-group float-sm-right mt-1-sm">
                <slot name="nav-item-right"/>
            </div>
        </div>
        <div class="tab-content" :style="cssProps">
            <slot/>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import expiringStorage from '../expiringStorage';
    import _ from 'lodash';
    import VueContext from "vue-context";

    export default {
        props: {
            viewClass: {
                default: '',
            },
            cacheLifetime: {
                default: 500000,
            },
            options: {
                type: Object,
                required: false,
                default: () => ({
                    useUrlFragment: true,
                    defaultTabHash: null,
                    useRightContextMenu: false,
                    isOrdered: false,
                }),
            },
            topGap: {
                type: Boolean,
                default: true,
            }
        },

        data: () => ({
            dataCacheLifetime: 500000,
            tabs: [],
            activeTabHash: '',
            activeTabIndex: 0,
            lastActiveTabHash: '',
        }),

        computed: {
            storageKey() {
                return `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`;
            },
            cssProps() {
                return {
                    'border': !this.topGap && 'none'
                };
            },
            computedTabs: function () {
                this.initTabs();
                let tabs = _.filter(this.tabs, function (i) {
                    return i.isTab && i.isShow;
                });
                if (this.options.isOrdered) {
                    tabs = _.orderBy(tabs, 'order');
                }
                return tabs;
            }
        },
        watch: {
            computedTabs(newValue) {
                // console.log('computedTabs', newValue);
                this.$nextTick(() => {
                    const previousSelectedTabHash = expiringStorage.get(this.storageKey);
                    //  console.log('previousSelectedTabHash', previousSelectedTabHash);
                    // console.log('this.findTab(previousSelectedTabHash)', this.findTab(previousSelectedTabHash));
                    if (this.findTab(previousSelectedTabHash)) {
                        this.selectTab(previousSelectedTabHash);
                        return;
                    }
                });
            }
        },
        created() {
            this.$nextTick(() => {
                this.initTabs();
            });
        },
        mounted() {
            this.$nextTick(() => {
                this.initTabs();
            });
            window.addEventListener('hashchange', () => this.selectTab(window.location.hash));
            //console.log('window.location.hash', window.location.hash);
            if (this.findTab(window.location.hash)) {
                this.selectTab(window.location.hash);
                return;
            }

            const previousSelectedTabHash = expiringStorage.get(this.storageKey);

            //console.log('previousSelectedTabHash', previousSelectedTabHash);

            this.$nextTick(() => {
                //console.log('this.findTab(previousSelectedTabHash)', this.findTab(previousSelectedTabHash));
                if (this.findTab(previousSelectedTabHash)) {
                    this.selectTab(previousSelectedTabHash);
                    return;
                }
                // console.log('this.options.defaultTabHash',this.options.defaultTabHash)
                //console.log('find',this.findTab("#" + this.options.defaultTabHash))
                if (this.options.defaultTabHash !== null && this.findTab("#" + this.options.defaultTabHash)) {
                    // console.log('set defaultTabHash', this.options.defaultTabHash)
                    this.selectTab("#" + this.options.defaultTabHash);
                    return;
                }

                if (this.computedTabs.length) {
                    let hash = '';
                    if (this.computedTabs[0].hash) {
                        hash = this.computedTabs[0].hash;
                    } else if (this.computedTabs[1].hash) {
                        hash = this.computedTabs[1].hash;
                    }
                    this.selectTab(hash, null, true);
                }
            });
        },

        methods: {
            initTabs() {
                let tabs = [];
                _.forEach(this.$children, function (t) {
                    if (t.name && t.name == 'assessment-items-list-tab') {
                        _.forEach(t.$children, function (tt) {
                            tabs.push(tt);
                        });
                    } else {
                        tabs.push(t);
                    }
                });
                this.tabs = tabs;
            },
            handleRightClick(e, i) {
                this.$emit('handleRightClick', e, i);
            },
            findTab(hash) {
                return this.computedTabs.find(tab => tab.hash === hash);
            },

            selectTab(selectedTabHash, event = null, isMounted = false) {
               //  console.log('selectTab', selectedTabHash, event)
                // return;

                // See if we should store the hash in the url fragment.
                if (event && !this.options.useUrlFragment) {
                    event.preventDefault();
                }

                const selectedTab = this.findTab(selectedTabHash);

                if (!selectedTab) {
                    return;
                }

                if (event && selectedTab.isDisabled) {
                    event.preventDefault();
                    return;
                }
               // console.log('index:', this.computedTabs.indexOf(selectedTab));
                if (this.lastActiveTabHash === selectedTab.hash && this.computedTabs.indexOf(selectedTab) === this.activeTabIndex) {
                    this.$emit('clicked', {tab: selectedTab});
                    return;
                }

                this.computedTabs.forEach(tab => {
                    tab.isActive = (tab.hash === selectedTab.hash);
                });

                this.$emit('changed', {tab: selectedTab});

                this.activeTabHash = selectedTab.hash;
                this.activeTabIndex = this.getTabIndex(selectedTabHash);

                this.lastActiveTabHash = this.activeTabHash = selectedTab.hash;
                if (!isMounted) {
                    expiringStorage.set(this.storageKey, selectedTab.hash, this.dataCacheLifetime);
                }
            },

            setTabVisible(hash, visible) {
                const tab = this.findTab(hash);

                if (!tab) {
                    return;
                }

                tab.isVisible = visible;

                if (tab.isActive) {
                    // If tab is active, set a different one as active.
                    tab.isActive = visible;

                    this.tabs.every((tab, index, array) => {
                        if (tab.isVisible) {
                            tab.isActive = true;

                            return false;
                        }

                        return true;
                    });
                }
            },

            getTabIndex(hash) {
                const tab = this.findTab(hash);

                return this.computedTabs.indexOf(tab);
            },

            getTabHash(index) {
                const tab = this.computedTabs.find(tab => this.computedTabs.indexOf(tab) === index);

                if (!tab) {
                    return;
                }

                return tab.hash;
            },

            getActiveTab() {
                return this.findTab(this.activeTabHash);
            },

            getActiveTabIndex() {
                return this.getTabIndex(this.activeTabHash);
            },
        },
        components: {
            VueContext,
        }
    };
</script>
<style scoped>

</style>
