const interfaceRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "interface-claims" */ "../views/dashboard/dashboard"),
        meta: {
            requiresAuth: true,
            isInsurance: true,
            isAccidentManagement: true,
            isAssessments: true,
        }
    },
    {
        path: '/claims',
        name: 'Claims',
        component: () => import(/* webpackChunkName: "interface-claims" */ "../views/claims/claims"),
        meta: {
            requiresAuth: true,
            isInsurance: true,
            isAccidentManagement: true,

        }
    },
    {
        path: '/claims/new',
        name: 'ClaimNew',
        component: () => import(/* webpackChunkName: "interface-claims" */ "../views/claims/claim-new"),
        meta: {requiresAuth: true}
    },
    {
        path: '/claims/:claimId',
        name: 'ClaimView',
        component: () => import(/* webpackChunkName: "interface-claims" */ "../views/claims/claim-view"),
        meta: {requiresAuth: true}
    },
    {
        path: '/claims/:claimId/:objectId/new-assessment',
        name: 'AssessmentNew',
        component: () => import(/* webpackChunkName: "interface-claims" */ "../views/assessments/assessment-new"),
        meta: {requiresAuth: true}
    },
    {
        path: '/repairers',
        name: 'Repairers',
        component: () => import(/* webpackChunkName: "interface-repairers" */ "../views/repairers/repairers"),
        meta: {requiresAuth: true}
    },
    {
        path: '/repairer/:repairerId',
        name: 'RepairerView',
        component: () => import(/* webpackChunkName: "interface-repairers" */ "../views/repairers/repairer-view"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessments',
        name: 'Assessments',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/assessments/assessments"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessments/:assessmentId(\\d+)',
        name: 'AssessmentView',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/assessments/assessment-view"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessments/:assessmentId/print-report',
        name: 'AssessmentPrintReport',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/assessments/assessment-print-report"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessments/:assessmentId(\\d+)/add-supplement',
        name: 'AssessmentAddSupplement',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/assessments/assessment-add-supplement"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessments/new',
        name: 'AssessmentNewForAssessmentCompany',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/assessments/assessment-new-for-assessment-company"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessments/estimate/:estimateId/print-report',
        name: 'AssessmentPrintReport',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/assessments/assessment-print-report"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessments/:assessmentId/send-notice-to-repairer',
        name: 'AssessmentSendNoticeToRepairers',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/assessments/assessment-send-notice-to-repairer"),
        meta: {requiresAuth: true}
    },
    {
        path: '/claims/:claimId/send-notice-to-repairer',
        name: 'ClaimSendNoticeToRepairers',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/claims/claim-send-notice-to-repairer"),
        meta: {requiresAuth: true}
    },
    {
        path: '/claims/:claimId/send-notice-to-repairer/:otherPartyId',
        name: 'ClaimSendNoticeToRepairers',
        component: () => import(/* webpackChunkName: "interface-assessments" */ "../views/claims/claim-send-notice-to-repairer"),
        meta: {requiresAuth: true}
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: () => import(/* webpackChunkName: "interface-invoices" */ "../views/invoices/invoices"),
        meta: {requiresAuth: true}
    },
    {
        path: '/invoice/:invoiceId',
        name: 'Invoices',
        component: () => import(/* webpackChunkName: "interface-invoices" */ "../views/invoices/invoice-view"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessors',
        name: 'Assessors',
        component: () => import(/* webpackChunkName: "interface-additional" */ "../views/assessors/assessors"),
        meta: {requiresAuth: true}
    },
    {
        path: '/assessor/:assessorId',
        name: 'AssessorView',
        component: () => import(/* webpackChunkName: "interface-additional" */ "../views/assessors/assessor-view"),
        meta: {requiresAuth: true}
    },
    {
        path: '/tow-drivers',
        name: 'TowDrivers',
        component: () => import(/* webpackChunkName: "interface-additional" */ "../views/tow-drivers/tow-drivers"),
        meta: {requiresAuth: true}
    },
    {
        path: '/tow-driver/:towDriverId',
        name: 'TowDriverView',
        component: () => import(/* webpackChunkName: "interface-additional" */ "../views/tow-drivers/tow-driver-view"),
        meta: {requiresAuth: true}
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "interface-settings" */ "@/views/Settings"),
        meta: {requiresAuth: true}
    },
];
export default interfaceRoutes;
